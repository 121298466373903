import React, { useEffect } from "react";
import { motion } from "framer-motion";
import {
  Stack /*this is for release 2*/,
  Box,
  Tabs /*this is for release 2*/,
  Tab /*this is for release 2*/,
  styled /*this is for release 2*/,
  Divider /*this is for release 2*/,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import TicketListPage from "../ticket_list_page"; /*this is for release 2*/

import Topics from "./components/topics";

import HomepageBanner from "./components/homepage_banner";
import * as workpalJS from "@workpal-build/workpaljs";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const LandingPage = ({ tabVal }) => {
  // For switching between tabs
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialTab =
    queryParams.get("tab") === "tickets"
      ? 1
      : 0; /* check params - for user to go to "Tickets Tab" from another page" */
  const [tabValue, setTabValue] = React.useState(initialTab);
  /*this is for release 2*/

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  }; /*this is for release 2*/

  //Tabs css
  /*this is for release 2*/
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      backgroundColor: "#376EEE",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      marginRight: theme.spacing(1),
      color: "#757575",
      "&.Mui-selected": {
        color: "#376EEE",
        fontWeight: 700,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  const userAgency = useSelector((state) => state.userapi.agency);

  // useEffect(() => {
  //   ReactGA.gtag("set", "user_properties", {
  //     agency: userAgency,
  //   });
  // }, []);

  return (
    <motion.div>
      <HomepageBanner />
      {/* {tabVal} */}

      {/* THIS STYLED TABS IS COMMENTED OUT TO HIDE HISTORY PAGE WHICH IS NEEDED FOR RELEASE 2 */}
      {/* <StyledTabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="secondary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <StyledTab label="Topics" {...a11yProps(0)} />
        <StyledTab label="Tickets" {...a11yProps(1)} />
      </StyledTabs>

      <Divider
        style={{
          background: "#D0D0D0",
          borderBottomWidth: "1px",
          borderColor: "rgba(0, 0, 0, 1)",
        }}
      /> */}
      <TabPanel value={tabValue} index={0} dir={theme.direction}>
        <Topics />
      </TabPanel>
      <TabPanel value={tabValue} index={1} dir={theme.direction}>
        <TicketListPage />
      </TabPanel>
    </motion.div>
  );
};
export default LandingPage;

// STYLED TABS FUNCTIONS
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/*this is for release 2*/
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
