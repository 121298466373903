import "./App.css";
import { React, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme.jsx";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as workpalJS from "@workpal-build/workpaljs";
import config from "./config";
import {
  fetchTopicIssues,
  fetchUserApiInfo,
  storeUserJWT,
  storeUserDeviceInfo,
  storeUserEmail,
  loading,
} from "./state/action_creators/index";
import AnimatedRoutes from "./views/components/AnimatedRoutes";
import axios from "axios";

import LoadingOverlay from "./views/components/loading_overlay";

import NoUserFoundPage from "./views/error_handling_page/no_user_found_page.jsx";
import ServerNoFoundPage from "./views/error_handling_page/server_not_found_page.jsx";
import ReactGA from "react-ga4";

function App() {
  //JS Bridge initialise
  const [init, setInit] = useState(false);
  const dispatch = useDispatch();

  const jwt = useSelector((state) => state.user.jwt);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    workpalJS.listener.registerListener(function (val, type) {
      switch (type) {
        case "init":
          // To start call WorkpalJS bridge functions after initialisation completed.
          // workpalJS.getUserInfo();
          // workpalJS.getSessionInfo();
          // workpalJS.getDeviceInfo();
          // workpalJS.closeWebView();
          // workpalJS.refreshAccessToken();
          // workpalJS.requestAttachFile();
          setInit(true);
          break;
        case "jwt":
          // let JWT = val <-- JWT returned from workpal after getSessionInfo() is called.
          dispatch(storeUserJWT(val));
          // alert("this is app.js -> storing jwt in redux");
          break;
        case "deviceInfo":
          var obj = JSON.parse(val);

          dispatch(storeUserDeviceInfo(obj));
          break;
        case "userInfo":
          var obj = JSON.parse(val);

          dispatch(storeUserEmail(obj.email));
          break;
        case "attachedFile":
          setDocs(val);
          break;
      }
    });
    workpalJS.init();
    // const doesAnyHistoryEntryExist = location.key !== "default";

    workpalJS.listener.registerBackpressedListener(function () {
      //   //TODO : Handle navigation stack callback
    });

    workpalJS.listener.registerPushNotificationListener(function (data) {
      //TODO : Handle push notification data callback
    });

    // ReactGA.initialize("G-T8B2KQNBN3", {
    //   gaOptions: {
    //     userId: "yannie1230003",
    //   },
    // });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "/",
    //   title: "Landing Page Mobile App",
    // });

    //WOGAA implementation
    // const script = document.createElement("script");

    // // script.src = "https://assets.wogaa.sg/scripts/wogaa.js";
    // script.src = "https://assets.dcube.cloud/scripts/wogaa.js";
    // script.async = true;

    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  useEffect(() => {
    if (init) {
      workpalJS.getUserInfo();
      workpalJS.getSessionInfo();
      workpalJS.getDeviceInfo();
    }
  }, [init]);

  useEffect(() => {
    if (jwt) {
      fetchTopicIssuesList(jwt);
      fetchUserInfoAPI(jwt);
    }
  }, [jwt]);

  const fetchUserInfoAPI = (jwt) => {
    axios
      .post(
        `${config.API_BASE_URL}userinfo`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.data.error) {
          setIsUser(false);
        } else {
          setIsUser(true);
          dispatch(fetchUserApiInfo(res.data.data));

          // Initialize GA4
          // this is for dev accouny
          // ReactGA.initialize("G-T8B2KQNBN3", {
          //   gaOptions: {
          //     userId: res.data.data.userSysId,
          //   },
          // });

          // this is for prod account
          ReactGA.initialize("G-EBDF2C5N21", {
            gaOptions: {
              userId: res.data.data.userSysId,
            },
          });

          ReactGA.gtag("set", "user_properties", {
            agency: res.data.data.agency,
            userId: res.data.data.userSysId,
          });
          ReactGA.gtag("event", "Load mini-app home", {
            sessionIdCustom: res.data.data.sessionID,
            timestamp: Date(),
            agency: res.data.data.agency,
            channel: "Mobile",
          });
        }
      });
    // .catch((e) => {
    //   alert(JSON.stringify(e));
    // });
  };

  const fetchTopicIssuesList = (jwt) => {
    axios
      .post(
        `${config.API_BASE_URL}topicissues`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const sortedTopics = res.data.data.result.sort(
          (a, b) => b.incident_count - a.incident_count
        );

        dispatch(fetchTopicIssues(sortedTopics));
        setIsLoading(false);
        // setLoadError(false);
      })
      .catch((e) => {
        setIsLoading(false);
        // workpalJS.closeWebView();
        setLoadError(true);
      });
  };

  // check if the user is using a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

  //local storage
  // localStorage.removeItem("viewOnboardingTutorial");

  return (
    <ThemeProvider theme={theme}>
      {/* <textarea value={jwt}></textarea> */}

      {
        // isMobile ? ( /*commented isMobile check out as PO requested to pause the check due to gomax users*/
        isLoading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingOverlay isOpen={isLoading} />
          </div>
        ) : (
          <Router>
            {isUser ? (
              loadError ? (
                <ServerNoFoundPage />
              ) : (
                <AnimatedRoutes />
              )
            ) : (
              <h1>{isUser}</h1>
              // <NoUserFoundPage />
            )}
          </Router>
        )
        // )
        // : (
        //   <div className="container mt-5" spacing={3}>
        //     <Alert severity="warning" variant="outlined">
        //       Please access the portal with a mobile device
        //     </Alert>
        //   </div>
        // )
      }
    </ThemeProvider>
  );
}

export default App;
